import { gql } from "graphql-tag";

export const IP_FIELDS = gql`
	fragment IpFields on IpAddress {
		id
		ip_address
		status
		path
		created_at
	}
`;

export const PAGINATION = gql`
	fragment Pagination on IpAddressPagination {
		total
		per_page
		current_page
		from
		to
		last_page
		has_more_pages
	}
`;

export default { IP_FIELDS, PAGINATION };
