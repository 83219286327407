import { gql } from "graphql-tag";
import { IP_FIELDS } from "@/modules/system/ip-filtering/graphql/Fragments";

export const CREATE_IP = gql`
	mutation CreateIp($ip: String, $status: Boolean) {
		create_ip(ip_address: $ip, status: $status) {
			...IpFields
		}
	}
	${IP_FIELDS}
`;

export const UPDATE_IP = gql`
	mutation UpdateIp($id: Int, $ip: String, $status: Boolean) {
		update_ip(id: $id, ip_address: $ip, status: $status) {
			...IpFields
		}
	}
	${IP_FIELDS}
`;

export const DELETE_IP = gql`
	mutation DeleteIp($id: Int!) {
		delete_ip(id: $id) {
			...IpFields
		}
	}
	${IP_FIELDS}
`;

export default { CREATE_IP, UPDATE_IP, DELETE_IP };
