
import { setPageHeader } from "@/core/helpers/toolbar";
import { defineComponent, onMounted } from "vue";
import AddIp from "@/modules/system/ip-filtering/components/AddIp.vue";
import EditIp from "@/modules/system/ip-filtering/components/EditIp.vue";
import IpList from "@/modules/system/ip-filtering/components/IpList.vue";

export default defineComponent({
	name: "sites",
	components: {
		IpList,
		AddIp,
		EditIp,
	},
	setup() {
		onMounted(() => {
			setPageHeader({
				title: "message.IP_ADDRESS",
				actionButton: {
					ability: "add_ip_filtering",
					pageAction: {
						action: "addIpAction",
					},
					button: {
						icon: "bi bi-plus",
						openModal: true,
					},
				},
				breadCrumbs: [
					{ name: "message.HOME", link: "/dashboard" },
					{ name: "message.SYSTEM", link: "#" },
					{ name: "message.SETTINGS", link: "/system/settings" },
					{ name: "message.IP_ADDRESS" },
				],
			});
		});
	},

	props: {
		widgetClasses: String,
	},
});
