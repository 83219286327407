
import { Modal } from "bootstrap";
import { defineComponent, ref, inject } from "vue";
import { Input, Select } from "@/components/input-elements";
import { IpUpdateFormData } from "../interfaces";
import { Apollo, Notify } from "@/core/services";
import { UPDATE_IP } from "../graphql/Mutations";
import { GET_IP, SEARCH_IP } from "@/modules/system/ip-filtering/graphql/Queries";
import { GET_SITES } from "@/modules/common/sites/graphql/Queries";
import { GET_COUNTRIES } from "@/modules/common/countries/graphql/Queries";
import { useI18n } from "vue-i18n";
import InnerLoader from "@/components/InnerLoader.vue";

export default defineComponent({
	name: "edit site",
	components: {
		Input,
		// Select,
		InnerLoader,
	},

	setup() {
		const modal: any = ref();
		const loading = ref(false);
		const emitter: any = inject("emitter");
		const i18n = useI18n();
		const editIpForm = ref<null | HTMLFormElement>(null);
		const countries = ref([]) as Record<any, any>;
		const showCountryInfo = ref([]) as Record<any, any>;

		// Given Store Form Interface
		const ipUpdateData = ref<IpUpdateFormData>({
			id: null,
			ip_address: "",
			status: 1,
		});

		// Submit Handler Request
		const submitHandlerForm = async () => {
			editIpForm.value?.validate(async (valid: boolean) => {
				if (valid) {
					loading.value = true;
					await Apollo.mutate({
						mutation: UPDATE_IP,
						variables: {
							id: ipUpdateData.value.id,
							ip: ipUpdateData.value.ip_address,
							status: ipUpdateData.value.status,
						},
						update: (store, { data: { update_ip } }) => {
							// const pervious_record = store.readQuery({
							// 	query: GET_IP,
							// 	variables: {
							// 		page: 1,
							// 		limit: 10,
							// 	},
							// }) as Record<any, any>;
							// store.writeQuery({
							// 	query: GET_IP,
							// 	variables: {
							// 		page: 1,
							// 		limit: 10,
							// 	},
							// 	data: {
							// 		get_ip: {
							// 			...pervious_record.get_ip,
							// 			data: [update_ip, ...pervious_record.get_ip.data],
							// 		},
							// 	},
							// });
							modal.value.hide();
							loading.value = false;
							Notify.success(`${i18n.t("message.RECORD_UPDATED_SUCCESSFULLY")}`);
						},
					}).catch(() => {
						loading.value = false;
					});
				}
			});
		};

		// Reset Form Data On Model Popup
		const resetForm = () => {
			emitter.emit("clearInput");
			editIpForm.value?.resetFields();
		};

		// Emitter To Open Model
		emitter.on("editIpActions", (ip: Record<any, any>) => {
			resetForm();
			ipUpdateData.value.id = ip.id;
			ipUpdateData.value.ip_address = ip.ip_address;
			ipUpdateData.value.status = ip.status == 1 ? true : false;

			modal.value = new Modal(document.getElementById("modal_edit_ip")) as HTMLElement;
			modal.value.show();
		});

		return {
			loading,
			ipUpdateData,
			editIpForm,
			countries,
			showCountryInfo,
			submitHandlerForm,
		};
	},
});
