
import { Modal } from "bootstrap";
import { defineComponent, ref, inject } from "vue";
import { Input, Select } from "@/components/input-elements";
import { IpAddFormData } from "../interfaces";
import { Apollo, Notify } from "@/core/services";
import { CREATE_IP } from "../graphql/Mutations";
import { GET_IP, SEARCH_IP } from "@/modules/system/ip-filtering/graphql/Queries";
import { GET_SITES } from "@/modules/common/sites/graphql/Queries";
import { GET_COUNTRIES } from "@/modules/common/countries/graphql/Queries";
import { useI18n } from "vue-i18n";
import InnerLoader from "@/components/InnerLoader.vue";

export default defineComponent({
	name: "add new ip address",
	components: {
		Input,
		// Select,
		InnerLoader,
	},

	setup() {
		const modal: any = ref();
		const loading = ref(false);
		const emitter: any = inject("emitter");
		const i18n = useI18n();
		const addIpForm = ref<null | HTMLFormElement>(null);
		const countries = ref([]) as Record<any, any>;
		const showCountryInfo = ref([]) as Record<any, any>;

		// Given Site Form Interface
		const ipData = ref<IpAddFormData>({
			ip_address: "",
			status: 1,
		});

		// Submit Handler Request
		const submitHandlerForm = async () => {
			addIpForm.value?.validate(async (valid: boolean) => {
				if (valid) {
					loading.value = true;
					await Apollo.mutate({
						mutation: CREATE_IP,
						variables: {
							ip: ipData.value.ip_address,
							status: ipData.value.status,
						},
						update: (store, { data: { create_ip } }) => {
							const pervious_record = store.readQuery({
								query: GET_IP,
								variables: {
									page: 1,
									limit: 10,
								},
							}) as Record<any, any>;
							store.writeQuery({
								query: GET_IP,
								variables: {
									page: 1,
									limit: 10,
								},
								data: {
									get_ip: {
										...pervious_record.get_ip,
										data: [create_ip, ...pervious_record.get_ip.data],
									},
								},
							});
							modal.value.hide();
							loading.value = false;
							Notify.success(`${i18n.t("message.RECORD_ADDED_SUCCESSFULLY")}`);
						},
					}).catch(() => {
						loading.value = false;
					});
				}
			});
		};

		// Reset Form Data On Model Popup
		const resetForm = () => {
			emitter.emit("clearInput");
			addIpForm.value?.resetFields();
		};

		// Emitter To Open Model
		emitter.on("addIpAction", () => {
			resetForm();
			modal.value = new Modal(document.getElementById("modal_add_ip")) as HTMLElement;
			modal.value.show();
		});

		return {
			loading,
			ipData,
			addIpForm,
			countries,
			showCountryInfo,
			submitHandlerForm,
		};
	},
});
